/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Snippets</h3>
            <vue-draggable-container id="wordBank" class="list wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer command-block">action drop</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">action forward</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">filter</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">permit</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">permit</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">deny</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">match</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row mt-base">
                <h1><span class="font-bold">SW1 (config)#</span> ip access-list extended DENY-HTTP</h1>
              </div>
              <div class="vx-row mt-base flex items-center">
                <h1 class="mr-8 font-bold">SW1 (config-ext-nacl)#</h1>
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg"
                  id="input0"
                  style="width: 10vw"
                ></vue-draggable-container>
                <h1 class="ml-8">TCP host 10.1.1.10 host 10.1.1.20 eq www</h1>
              </div>

              <br />

              <div class="vx-row mt-base">
                <h1><span class="font-bold">SW1 (config)#</span> ip access-list extended MATCH_ALL</h1>
              </div>
              <div class="vx-row mt-base">
                <h1 class="mr-8 font-bold">SW1 (config-ext-nacl)#</h1>
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg"
                  id="input1"
                  style="width: 10vw"
                ></vue-draggable-container>
                <h1 class="ml-8">ip any any</h1>
              </div>

              <br />

              <div class="vx-row mt-base">
                <h1><span class="font-bold">SW1 (config)#</span> vlan access-map HOST-A-B 10</h1>
              </div>
              <div class="vx-row mt-base">
                <h1><span class="font-bold">SW1 (config-access-map)#</span> match ip address DENY-HTTP</h1>
              </div>
              <div class="vx-row mt-base">
                <h1 class="mr-8 font-bold">SW1 (config-access-map)#</h1>
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg"
                  id="input2"
                  style="width: 10vw"
                ></vue-draggable-container>
              </div>

              <br />

              <div class="vx-row mt-base">
                <h1><span class="font-bold">SW1 (config)#</span> vlan access-map HOST-A-B 20</h1>
              </div>
              <div class="vx-row mt-base">
                <h1><span class="font-bold">SW1 (config-access-map)#</span> match ip address MATCH_ALL</h1>
              </div>
              <div class="vx-row mt-base">
                <h1 class="mr-8 font-bold">SW1 (config-access-map)#</h1>
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg"
                  id="input3"
                  style="width: 10vw"
                ></vue-draggable-container>
              </div>

              <br />

              <div class="vx-row mt-base">
                <h1><span class="font-bold">SW1 (config)#</span> vlan filter HOST-A-B VLAN 10</h1>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-command';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'permit') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#77FAAE';
        document.getElementById('input0').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input1').textContent === 'permit') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#77FAAE';
        document.getElementById('input1').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input2').textContent === 'action drop') {
        totalScore++;
        document.getElementById('input2').style.borderColor = '#77FAAE';
        document.getElementById('input2').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input3').textContent === 'action forward') {
        totalScore++;
        document.getElementById('input3').style.borderColor = '#77FAAE';
        document.getElementById('input3').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.currently-dragging-standard-command {
  background-color: #0042ff !important;
  padding: 12px;
  color: white;
  z-index: 99;
  min-width: auto;
}

.command-block {
  display: inline-flex;
  max-height: 100px;
  margin: 5px;
  padding: 10px;
  text-align: left;
  color: #fff;
  border: 1px solid #3f3f3f;
  background-color: #fd4445;
  border-radius: 5px;
}
</style>
